
.npoitem__ob{
  margin-top: -3%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
  align-items: flex-start;
}
.npoitem__ob-title{
  font-size: 30px!important;
  font-family: Roboto, Helvetica, Arial, Lucida, sans-serif!important;
  font-weight: 700!important;
  line-height: 1.2em!important;
  color: #333!important;
}
.npo__ob-list{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.npo__ob-text{
  max-width: 450px;
}
.npo__ob-item{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
}
.npo__ob-img{
  width: 230px;
  border-radius: 20px;
}
.npo__ob-img.large{
  height: 250px;
  width: 300px;
}
.npoitem__gallery{
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title{
    font-size: 30px!important;
    font-family: Roboto, Helvetica, Arial, Lucida, sans-serif!important;
    font-weight: 700!important;
    line-height: 1.2em!important;
    color: #333!important;
  }
  &-list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 14px;
    grid-column-gap: 14px;
  }
}
.npo__gallery-item img{
  border-radius: 30px;
  border: 3px solid rgba(34, 31, 98, 0.7);
}
@media(max-width: 1023px){
  .npoitem__ob{
    align-items: center;
  }
  .npo__ob-list{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
  }
  .npo__ob-item{
    flex-direction: column;
    align-items: center;
  }
  .npo__ob-img{
    width: 250px;
  }
  .npo__ob-text{
    text-align: center;
  }
  .npoitem__ob-title{
    text-align: center;
  }
  .npoitem__gallery-title{
    text-align: center;
  }
}
@media(max-width: 767px){
  .npoitem__gallery-list{
    display: flex;
    flex-direction: column;
  }
}
