.fl{
  margin-top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  &__title{
    font-size: 33px !important;
    font-family: Roboto, Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    line-height: 1.2em !important;
    color: #333 !important;
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 80px;
  }
  &__item{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    &-content{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;
    }
  }
  &__img{
    width: 96px;
    height: 96px;
  }
  &__item-title{
    font-size: 20px!important;
  }
  &__text{
    font-size: 15px;
  }
  
  @media(max-width: 1023px){
    &__list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 50px;
    }
    &__text{
      max-width: 480px;
    }
  }
  @media(max-width: 500px){
    &__item{
      gap: 15px;
      flex-direction: column;
      align-items: center;
      &-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        .fl__item-title{
          text-align: center;
        }
        .fl__text{
          text-align: center;
        }
      }
    }
    &__list{
      gap: 45px;
    }
    &__img{
      width: 75px;
      height: 75px;
    }
  }
}

.tech{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 70px;
  margin-top: 0%;
  &__title{
    font-size: 33px !important;
    font-family: Roboto, Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 700 !important;
    line-height: 1.2em !important;
    color: #333 !important;
    text-align: center;
  }
  &__list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 60px;
  }
  &__item{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }
  &__line{
    width: 60px;
    height: 3px;
    background-color: #4c2264;
  }
  &__text{
    font-size: 16px;
  }
  @media(max-width: 767px){
    &__list{
      grid-template-columns: 1fr 1fr;
    }
  }
  @media(max-width: 578px){
    &__item{
      gap: 10px;
    }
    &__list{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 30px;
    }
  }
}

.modal{
  z-index: 12;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(43,41,96,0.88);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  &__img{
    height: auto;
    max-height: 80%;
    max-width: 97%;
    border-radius: 10px;
    margin-bottom: auto;
    background-color: white;
  }
  &__btn{
    width: 40px;
    height: 40px;
    align-self: flex-end;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
    margin-bottom: auto;
  }
}