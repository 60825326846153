.uzi-item{
  margin-top: 5%;
  @media(max-width: 500px){
    margin-top: 15%;
  }
  &__inner{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 160px;
  }
  &-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 50px;
  }
  &-slider{
    width: 420px;
    img{
      width: 100%;
    }
  }
  &-desc{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
  }
  &-title{
    font-size: 34px;
  }
  &-subtitle{
    font-size: 19px!important;
    font-weight: 500;
  }
  &-text{
    font-size: 17px;
    font-weight: 400;
  }
  &-link{
    color: #fff;
    text-decoration: none;
    margin-top: 10px;
    border-width: 0 !important;
    border-color: transparent;
    border-radius: 4px;
    letter-spacing: 3px;
    font-size: 12px;
    font-weight: 700 !important;
    text-transform: uppercase !important;
    background-image: linear-gradient( 77deg, #292562 31%, #080060 100% ) !important;
    padding: 6px 14px;
    display: inline-block;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    &:hover{
      letter-spacing: 4px;
    }
  }
  .slick-dots{
    li{
      button{
        &::before{
          font-size: 10px;
        }
      }
    }
  }
  &-oss{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    &-title{
      font-size: 38px;
    }
    &-list{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 30px;
      grid-row-gap: 60px;
    }
    &-item{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;
      &-title{
        font-weight: 500;
        font-size: 24px!important;
      }
      &-text{
        font-weight: 400;
      }
    }
  }
  .uzi-item-slider2{
    width: 100%;
    margin-top: -100px;
    .slider2-item{
      display: flex!important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      &:active{
        cursor: grabbing;
      }
    }
    .slider2-img{
      align-self: center;
    }
    .slider2-title{
      font-size: 16px!important;
      font-weight: 700;
    }
    .slider2-text{
      font-size: 16px!important;
      font-weight: 500;
      max-width: 90%;
    }
    .slick-arrow.slick-prev{
      left: -30px;
      z-index: 1;
      width: 30px;
      height: 35px;
      // transition: all 0.3s ease;
      background: url('../../../../assets/catalog/uzi/scan/arrowLeft.svg') 0 0 / 100% no-repeat;
      &:hover{
        background: url('../../../../assets/catalog/uzi/scan/arrowLeftHover.svg') 0 0 / 100% no-repeat;
      }
      &::before{
        content: '';
      }
    }
    .slick-arrow.slick-next{
      right: -30px;
      z-index: 1;
      width: 30px;
      height: 35px;
      // transition: all 0.3s ease;
      background: url('../../../../assets/catalog/uzi/scan/arrowRight.svg') 0 0 / 100% no-repeat;
      &:hover{
        background: url('../../../../assets/catalog/uzi/scan/arrowRightHover.svg') 0 0 / 100% no-repeat;
      }
      &::before{
        content: '';
      }
    }
  }
  .slider2-wrapper{
    margin-top: -70px;  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    .slider-titlee{
      font-size: 20px;
    }
    .slider-textt{
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
    }
  }
  @media(max-width: 1023px){
    .slick-arrow.slick-prev{
      left: -20px!important;
    }
    .slick-arrow.slick-next{
      right: -20px!important;
    }
  }
  @media(max-width: 1023px){
    .slick-arrow.slick-prev{
      left: -10px!important;
    }
    .slick-arrow.slick-next{
      right: -10px!important;
    }
    .slider2-wrapper{
      align-items: center;
    }
    .slider-textt{
      text-align: center;
    }
  }

  @media(max-width: 969px){
    &__inner{
      gap: 130px;
    }
    &-content{
      flex-direction: column;
      align-items: center;  
      gap: 90px;
    }
    &-desc{
      align-items: center;
      max-width: 90%;
      margin: 0 auto;
    }
    &-title, &-subtitle, &-text{
      text-align: center;
    }
    &-oss{
      align-items: center;
    }
    .slider-textt{
      font-weight: 400!important;
    }
    .slider2-text{
      font-weight: 400!important;
    }
  }
  @media(max-width: 767px){
    &__inner{
      gap: 120px;
    }
    &-content{
      gap: 60px;
    }
    &-desc{
      max-width: 100%;
    }
    &-text{
      font-weight: 350;
    }
    &-oss{
      align-items: center;
      gap: 25px;
    }
    &-oss-list{
      grid-template-columns: 1fr;
    }
    &-oss-item{
      align-items: center;
    }
    &-slider{
      width: 320px;
    }
    &-oss-item-text{
      text-align: center;
    }
    &-oss-item-title{
      text-align: center;
      margin-bottom: 15px;
    }
    
  }
  @media(max-width: 400px){
    &__inner{
      gap: 100px;
    }
    &-oss{
      gap: 50px;
    }
    &-oss-item-title{
      font-size: 23px!important;
    }
    &-slider{
      width: 280px;
    }
  }
}

